

























































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Chart } from '@/models'

@Component({})
export default class MapChart extends Vue {
  @Prop({ type: Object, required: true }) chart!: Chart
  @Prop({ type: Object, required: true }) result!: any
  @Prop({ type: Object, default: () => ({}) }) queryOptions!: Record<
    string,
    any
  >
  @Prop({ type: Boolean, default: false }) preview!: boolean
  @Prop({ type: Object, default: () => ({}) }) viewParams!: Record<string, any>
  @Prop({ type: Boolean, default: false }) loading!: boolean

  zoom = 0
  center = [0, 0]
  rotation = 0
  layers = []

  @Watch('result', { immediate: true })
  updateMap() {
    this.center = (this.result.initialCoordinates || '-70.65, -33.45')
      .split(',')
      .map((p: any) => parseFloat(p.trim()))
    this.zoom = this.result.initialZoom
    this.layers = this.result.layers || []
  }
}
